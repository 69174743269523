import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { __ } from "services/Translator";
import moment from "moment";
import api from "services/api";
import moneyFormatter from "services/moneyFormatter";

export default function Winners(props) {
  const [winnerTab, setWinnerTab] = useState("recent");
  const maxRecentWinnersLength = 50;


  const dummyWinners = [
    {
      game: {
        id: 4,
        game_id: "pragmaticexternal:GatesOfOlympus1",
        slug: "gatesofolympus1",
        game_name: "Gates Of Olympus",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/pragmaticexternal/GatesOfOlympus1.png",
        game_name_slugged: "Gates_Of_Olympus",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: false,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "",
        player_surname: "",
        identity: "1",
        player_nickname: "blurryalan",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 0.25,
      win_amount_float: 6.44,
      bet_amount: "0.25",
      win_amount: "6.44",
      profit_float: 6.19,
      profit: "6.19",
      record_date: "2024-07-27 02:36:26",
      type: "cash",
      tr_id: 28472404,
    },
    {
      game: {
        id: 30630,
        game_id: "yggdrasil:777VoltGigaBlox",
        slug: "777-volt-gigablox",
        game_name: "777 Volt GigaBlox",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/yggdrasil/777VoltGigaBlox.png",
        game_name_slugged: "777_Volt_GigaBlox",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: true,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "Omar",
        player_surname: "Talidi",
        identity: "4",
        player_nickname: "Ohmzlaw",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 1,
      win_amount_float: 1,
      bet_amount: "1.00",
      win_amount: "1.00",
      profit_float: 0,
      profit: "0.00",
      record_date: "2024-07-27 02:36:25",
      type: "bonus",
      tr_id: 28472403,
    },
    {
      game: {
        id: 30630,
        game_id: "yggdrasil:777VoltGigaBlox",
        slug: "777-volt-gigablox",
        game_name: "777 Volt GigaBlox",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/yggdrasil/777VoltGigaBlox.png",
        game_name_slugged: "777_Volt_GigaBlox",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: true,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "Omar",
        player_surname: "Talidi",
        identity: "4",
        player_nickname: "Ohmzlaw",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 1,
      win_amount_float: 0.6,
      bet_amount: "1.00",
      win_amount: "0.60",
      profit_float: -0.4,
      profit: "-0.40",
      record_date: "2024-07-27 02:36:16",
      type: "cash",
      tr_id: 28472400,
    },
    {
      game: {
        id: 30630,
        game_id: "yggdrasil:777VoltGigaBlox",
        slug: "777-volt-gigablox",
        game_name: "777 Volt GigaBlox",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/yggdrasil/777VoltGigaBlox.png",
        game_name_slugged: "777_Volt_GigaBlox",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: true,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "Omar",
        player_surname: "Talidi",
        identity: "4",
        player_nickname: "Ohmzlaw",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 1,
      win_amount_float: 7,
      bet_amount: "1.00",
      win_amount: "7.00",
      profit_float: 6,
      profit: "6.00",
      record_date: "2024-07-27 02:35:39",
      type: "bonus",
      tr_id: 28472387,
    },
    {
      game: {
        id: 30630,
        game_id: "yggdrasil:777VoltGigaBlox",
        slug: "777-volt-gigablox",
        game_name: "777 Volt GigaBlox",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/yggdrasil/777VoltGigaBlox.png",
        game_name_slugged: "777_Volt_GigaBlox",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: true,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "Omar",
        player_surname: "Talidi",
        identity: "4",
        player_nickname: "Ohmzlaw",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 1,
      win_amount_float: 0.8,
      bet_amount: "1.00",
      win_amount: "0.80",
      profit_float: -0.19999999999999996,
      profit: "-0.20",
      record_date: "2024-07-27 02:35:00",
      type: "cash",
      tr_id: 28472375,
    },
    {
      game: {
        id: 4,
        game_id: "pragmaticexternal:GatesOfOlympus1",
        slug: "gatesofolympus1",
        game_name: "Gates Of Olympus",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/pragmaticexternal/GatesOfOlympus1.png",
        game_name_slugged: "Gates_Of_Olympus",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: false,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "",
        player_surname: "",
        identity: "1",
        player_nickname: "blurryalan",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 0.25,
      win_amount_float: 0.43,
      bet_amount: "0.25",
      win_amount: "0.43",
      profit_float: 0.18,
      profit: "0.18",
      record_date: "2024-07-27 02:34:57",
      type: "cash",
      tr_id: 28472372,
    },
    {
      game: {
        id: 30630,
        game_id: "yggdrasil:777VoltGigaBlox",
        slug: "777-volt-gigablox",
        game_name: "777 Volt GigaBlox",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/yggdrasil/777VoltGigaBlox.png",
        game_name_slugged: "777_Volt_GigaBlox",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: true,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "Omar",
        player_surname: "Talidi",
        identity: "4",
        player_nickname: "Ohmzlaw",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 1,
      win_amount_float: 0.2,
      bet_amount: "1.00",
      win_amount: "0.20",
      profit_float: -0.8,
      profit: "-0.80",
      record_date: "2024-07-27 02:34:51",
      type: "bonus",
      tr_id: 28472369,
    },
    {
      game: {
        id: 4,
        game_id: "pragmaticexternal:GatesOfOlympus1",
        slug: "gatesofolympus1",
        game_name: "Gates Of Olympus",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/pragmaticexternal/GatesOfOlympus1.png",
        game_name_slugged: "Gates_Of_Olympus",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: false,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "",
        player_surname: "",
        identity: "1",
        player_nickname: "blurryalan",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 0.25,
      win_amount_float: 0.76,
      bet_amount: "0.25",
      win_amount: "0.76",
      profit_float: 0.51,
      profit: "0.51",
      record_date: "2024-07-27 02:34:49",
      type: "bonus",
      tr_id: 28472368,
    },
    {
      game: {
        id: 4,
        game_id: "pragmaticexternal:GatesOfOlympus1",
        slug: "gatesofolympus1",
        game_name: "Gates Of Olympus",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/pragmaticexternal/GatesOfOlympus1.png",
        game_name_slugged: "Gates_Of_Olympus",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: false,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "",
        player_surname: "",
        identity: "1",
        player_nickname: "blurryalan",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 0.25,
      win_amount_float: 0.28,
      bet_amount: "0.25",
      win_amount: "0.28",
      profit_float: 0.030000000000000027,
      profit: "0.03",
      record_date: "2024-07-27 02:34:40",
      type: "cash",
      tr_id: 28472360,
    },
  ];

  // const [recentWinners, setRecentWinners] = useState([]); //Enable when site has real data.
  const [recentWinners, setRecentWinners] = useState(dummyWinners);

  const getRecentWinners = () => {
    // return; //Enable when site has real data.
    setRecentWinners((prevRecentWinners) => {
      var maxId = null;
      if (prevRecentWinners.length) {
        maxId = Math.max(...prevRecentWinners.map((item, index) => item.tr_id));
      }
      var tmp = prevRecentWinners;
      api.get("/games/recent-bets", { limit: 9, newerThan: maxId }).then((response) => {
        if (response.data.data.length) {
          console.log("new Data received with length " + response.data.data.length);
          var newLength = response.data.data.length + tmp.length;
          console.log("New length " + newLength);
          if (newLength > maxRecentWinnersLength) {
            console.log("new length greater than current list");
            tmp.splice(0, newLength - maxRecentWinnersLength);
            tmp = tmp.concat(response.data.data);
            setRecentWinners(tmp);
          } else {
            console.log("new length fits");
            setRecentWinners(tmp.concat(response.data.data));
          }
        } else {
          console.log("No new data received");
          moveLastToFirst();
        }
      });
      return tmp;
    });
  };

  // const [topWinners, setTopWinners] = useState([]);  //Enable when site has real data.
  const [topWinners, setTopWinners] = useState(dummyWinners);

  const getTopWinners = () => {
    return; //Enable when site has real data.
    setTopWinners((prevTopWinners) => {
      var maxId = null;
      if (prevTopWinners.length) {
        maxId = Math.max(...setTopWinners.map((item, index) => item.tr_id));
      }
      api.get("/games/top-winners", { limit: 9, newerThan: maxId }).then((response) => {
        setTopWinners(prevTopWinners.concat(response.data.data));
      });
      return prevTopWinners;
    });
  };

  const checkIframe = () => {
    if (window.parent.parent.location.href != window.location.href) {
      window.parent.location.href = window.location.href;
    } else {
    }
  };

  useEffect(() => {
    getTopWinners();
    getRecentWinners();
    checkIframe();
  }, []);

  const [moveWinnerList, setMoveWinnerList] = useState(false);

  // useEffect(async () => {
  //   var timeout;
  //   setMoveWinnerList(false);
  //   timeout = setInterval(() => {
  //     setMoveWinnerList((prevMoveWinnerList) => {
  //       console.log("moveWinnerList", prevMoveWinnerList);
  //       return !prevMoveWinnerList;
  //     });
  //   }, 2500);

  //   return () => {
  //     clearInterval(timeout);
  //   };
  // }, []);

  const moveLastToFirst = () => {
    // return; //disabled rotating as requested
    setRecentWinners((prevRecentWinners) => {
      if (prevRecentWinners.length > 0) {
        const newArray = [...prevRecentWinners];

        // Remove the last element from the array
        const lastElement = newArray.pop();

        // Add the last element to the beginning of the array
        newArray.unshift(lastElement);

        // Log the modified array for debugging
        // console.log("newArray", newArray);

        // Return the modified array
        return newArray;
      }

      // If the array is empty, return the current state
      return prevRecentWinners;
    });

    setMoveWinnerList((prevMoveWinnerList) => {
      console.log("moveWinnerList", prevMoveWinnerList);
      setMoveWinnerList(!prevMoveWinnerList);
      setTimeout(() => {
        setMoveWinnerList(prevMoveWinnerList);
      }, 1000);
      return prevMoveWinnerList;
    });
  };

  const randomNumberInRange = (min, max) => parseInt(Math.random() * (max - min) + min);

  const setIntervalForRotatingWinners = (rounds) => {
    rounds = ++rounds % 5;
    if (!rounds) {
      getRecentWinners();
    } else {
      moveLastToFirst();
    }
    var interval = randomNumberInRange(500, 3000);
    const intervalId = setTimeout(() => {
      setIntervalForRotatingWinners(rounds);
    }, interval);
    return intervalId;
  };

  useEffect(() => {
    var rounds = 0;
    // Adjust the interval time as needed
    const intervalId = setIntervalForRotatingWinners(rounds);
    // Clear the interval on component unmount
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className="winner_section_v2">
      <div className="winner_headline" id="recent_winners">
        {__("Recent Bets")}
      </div>
      <div className="winner_body">
        <div className="dataset_filter_area">
          <div className="winner_filter">
            <ul>
              <li>
                <Link onClick={() => setWinnerTab("recent")} className={"common_btn  " + (winnerTab === "recent" ? "active" : "")}>
                  <span className="text">Recent Bets</span>
                </Link>
              </li>
              <li>
                <Link onClick={() => setWinnerTab("top")} className={"common_btn  " + (winnerTab === "top" ? "active" : "")}>
                  <span className="text">Big Winner</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="dataset_heading_area">
          <div className="dataset">
            {!props.isMobileDevice && <div className="item">{__("Game")}</div>}
            <div className="item">{__("User")}</div>
            <div className="item">{__("Time")}</div>
            {!props.isMobileDevice && <div className="item">{__("Wagers")}</div>}
            <div className="item">{__("Multiplier")}</div>
            <div className="item">{__("Payout")}</div>
          </div>
        </div>

        <div
          className={
            "dataset_content_area animation animation--shadow " +
            (moveWinnerList ? "animation--start " : " ") +
            (winnerTab == "recent" ? "" : "d-none")
          }
        >
          <div className="wrap">
            {recentWinners.length > 0 &&
              recentWinners.map((value, index) => (
                <>
                  <div className="dataset" key={index}>
                    {!props.isMobileDevice && (
                      <>
                        {props.isAuthenticated && (
                          <Link to={"/play/" + value.game.slug} className="item gameName">
                            <span className="icon">
                              <img alt="" src={value?.game.game_snapshot_url} style={{ width: 40 }} />
                            </span>{" "}
                            <span className="text">{value.game.game_name}</span>
                          </Link>
                        )}

                        {!props.isAuthenticated && (
                          <Link
                            onClick={() => {
                              props.setModalSignUpPropsData(true);
                              setTimeout(function () {
                                props.setModalSignUpPropsData(false);
                              }, 500);
                            }}
                            className="item gameName"
                          >
                            <span className="icon">
                              <img alt="" src={value.game.game_snapshot_url} style={{ width: 40 }} />
                            </span>{" "}
                            <span className="text">{value.game.game_name}</span>
                          </Link>
                        )}
                      </>
                    )}

                    <div className="item playerName">
                      {" "}
                      {value.player?.player_nickname ? (
                        value.player?.player_nickname
                      ) : (
                        <>
                          <svg
                            version="1.1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 100 125"
                            enable-background="new 0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g>
                              <path d="M48.1,52.1c17.4,0,37.5-4,39.3-7.6S65,36.1,65,36.1c-5.8,2.7-17,2.7-17,2.7s-11.2,0-17-2.7c0,0-24.1,4.9-22.3,8.5   C10.6,48.1,30.2,52.1,48.1,52.1z" />
                              <path d="M63.1,29.2c0,0-3.4-19.4-5.9-23.2c-2.5-3.8-6.3,4.5-10.2,4.5c-3.9,0-4.8-5.3-8.3-5.5c-3.6-0.2-7.1,24.2-7.1,24.2   S50,33.4,63.1,29.2z" />
                              <path d="M31.5,53.3c0,0-1.6,7.4,8,7.6c0,0,3.7,0,5.6-3.3c0.6-1,1.5-1.6,2.7-1.6h0.4c1.1,0,2.1,0.6,2.7,1.6c1.9,3.3,5.6,3.3,5.6,3.3   c9.6-0.2,8-7.6,8-7.6c-4.4,1.5-11.3,1.4-13.7,1.3h0c-0.6,0-0.9,0-0.9,0c0,0,0,0,0,0l-3.8,0C46.2,54.5,36.9,55,31.5,53.3z" />
                              <path d="M27.7,53.3l-7.4-1.1l-12.8,11c0.3,0,0.8,0,1.2,0c5.7,0,20.8,4.8,20.8,4.8l-8,6.9c6,1.2,24.9,11.3,24.9,11.3l1.8-6.6   C33.1,68.5,27.7,53.3,27.7,53.3z" />
                              <path d="M92.6,62.9L77.1,52.1l-8.3,1.1c0.5,2.7-1.2,7.6-1.2,7.6C43.7,78.9,47.6,95,47.6,95C57.1,76.2,78,73.6,78,73.6l-9.5-4.5   C74.7,65.2,92.6,62.9,92.6,62.9z" />
                            </g>
                          </svg>
                          {__("Anonymous")}
                        </>
                      )}
                    </div>
                    <div className="item">{moment(value.record_date, "YYYY-MM-DD HH:mm:ss").format("hh:mm A")}</div>
                    {!props.isMobileDevice && (
                      <div className="item">
                        {moneyFormatter.currencyIcon("USD")}
                        {value.bet_amount}
                      </div>
                    )}
                    <div className="item mult">
                      <span className={"text " + (value.win_amount_float > 0 ? "green" : "")}>
                        {value.win_amount_float > 0
                          ? (value.win_amount_float / value.bet_amount_float).toFixed(2) + "X"
                          : "0.00X"}
                      </span>
                    </div>
                    <div className="item payout">
                      {value.win_amount_float > 0 ? (
                        <span className="icon">{moneyFormatter.currencyIcon("USD")}</span>
                      ) : (
                        <span className="icon">{moneyFormatter.currencyIcon("USDGRAY")}</span>
                      )}
                      <span className={"text " + (value.win_amount_float > 0 ? "green" : "")}>{value.win_amount}</span>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>

        <div className={"dataset_content_area " + (winnerTab == "top" ? "" : "d-none")}>
          <div className="wrap">
            {topWinners.length > 0 &&
              topWinners.map((value, index) => (
                <div className="dataset" key={index}>
                  {!props.isMobileDevice && (
                    <>
                      {props.isAuthenticated && (
                        <Link to={"/play/" + value.game.slug} className="item gameName">
                          <span className="icon">
                            <img alt="" src={value.game.game_snapshot_url} style={{ width: 40 }} />
                          </span>{" "}
                          <span className="text">{value.game.game_name}</span>
                        </Link>
                      )}

                      {!props.isAuthenticated && (
                        <Link
                          onClick={() => {
                            props.setModalSignUpPropsData(true);
                            setTimeout(function () {
                              props.setModalSignUpPropsData(false);
                            }, 500);
                          }}
                          className="item gameName"
                        >
                          <span className="icon">
                            <img alt="" src={value?.game.game_snapshot_url} style={{ width: 40 }} />
                          </span>{" "}
                          <span className="text">{value?.game.game_name}</span>
                        </Link>
                      )}
                    </>
                  )}

                  <div className="item playerName">
                    {value?.player?.player_nickname ? (
                      value?.player?.player_nickname
                    ) : (
                      <>
                        <svg
                          version="1.1"
                          x="0px"
                          y="0px"
                          viewBox="0 0 100 125"
                          enable-background="new 0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path d="M48.1,52.1c17.4,0,37.5-4,39.3-7.6S65,36.1,65,36.1c-5.8,2.7-17,2.7-17,2.7s-11.2,0-17-2.7c0,0-24.1,4.9-22.3,8.5   C10.6,48.1,30.2,52.1,48.1,52.1z" />
                            <path d="M63.1,29.2c0,0-3.4-19.4-5.9-23.2c-2.5-3.8-6.3,4.5-10.2,4.5c-3.9,0-4.8-5.3-8.3-5.5c-3.6-0.2-7.1,24.2-7.1,24.2   S50,33.4,63.1,29.2z" />
                            <path d="M31.5,53.3c0,0-1.6,7.4,8,7.6c0,0,3.7,0,5.6-3.3c0.6-1,1.5-1.6,2.7-1.6h0.4c1.1,0,2.1,0.6,2.7,1.6c1.9,3.3,5.6,3.3,5.6,3.3   c9.6-0.2,8-7.6,8-7.6c-4.4,1.5-11.3,1.4-13.7,1.3h0c-0.6,0-0.9,0-0.9,0c0,0,0,0,0,0l-3.8,0C46.2,54.5,36.9,55,31.5,53.3z" />
                            <path d="M27.7,53.3l-7.4-1.1l-12.8,11c0.3,0,0.8,0,1.2,0c5.7,0,20.8,4.8,20.8,4.8l-8,6.9c6,1.2,24.9,11.3,24.9,11.3l1.8-6.6   C33.1,68.5,27.7,53.3,27.7,53.3z" />
                            <path d="M92.6,62.9L77.1,52.1l-8.3,1.1c0.5,2.7-1.2,7.6-1.2,7.6C43.7,78.9,47.6,95,47.6,95C57.1,76.2,78,73.6,78,73.6l-9.5-4.5   C74.7,65.2,92.6,62.9,92.6,62.9z" />
                          </g>
                        </svg>
                        {__("Anonymous")}
                      </>
                    )}
                  </div>
                  <div className="item">{moment(value?.record_date, "YYYY-MM-DD HH:mm:ss").format("hh:mm A")}</div>
                  {!props.isMobileDevice && (
                    <div className="item">
                      {moneyFormatter.currencyIcon("USD")}
                      {value.bet_amount}
                    </div>
                  )}
                  <div className="item mult">
                    <span className={"text " + (value.win_amount_float > 0 ? "green" : "")}>
                      {value.win_amount_float > 0
                        ? (value.win_amount_float / value.bet_amount_float).toFixed(2) + "X"
                        : "0.00X"}
                    </span>
                  </div>
                  <div className="item payout">
                    {value.win_amount_float > 0 ? (
                      <span className="icon">{moneyFormatter.currencyIcon("USD")}</span>
                    ) : (
                      <span className="icon">{moneyFormatter.currencyIcon("USDGRAY")}</span>
                    )}
                    <span className={"text " + (value.win_amount_float > 0 ? "green" : "")}>{value.win_amount}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
