import React from "react";
import Slider from "react-slick";

const ProcessLevels = () => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,

        arrows: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // className: "center",
                    // centerMode: true,
                },
            },
        ],
    };
    return (
        <div className="process-levels">
            <h2 className="vipHeading">
                What's the <span >Process of Levels?</span>
            </h2>
            <p className="description">
                Begin your gaming adventure at the initial Level – Newbie. The more you engage,
                the greater your Level becomes. It rises based on the bets placed during your Zino escapades.
            </p>

            <div className="levels-container">
                <Slider {...settings}>
                    <div className="sliderContent">
                        <div className="level-card">
                            <h3>01</h3>
                            <h4>
                                Login on <span className="highlight">Zino</span>
                            </h4>
                            <p>
                                Sign in to Zino! Experience the ease of our streamlined system—process
                                your deposits and start your gaming world.
                            </p>
                        </div>
                    </div>
                    <div className="sliderContent">
                        <div className="level-card">
                            <h3>02</h3>
                            <h4>
                                Engage in <span className="highlight">Games</span> and <span className="highlight">Wager on Sports</span>
                            </h4>
                            <p>
                                Delight in enjoying an exclusive fair gaming experience and exceptional service.
                            </p>
                        </div>
                    </div>
                    <div className="sliderContent">
                        <div className="level-card">
                            <h3>03</h3>
                            <h4>
                                Elevate Your <span className="highlight">Status</span>
                            </h4>
                            <p>
                                Climb to higher Ranker levels! New offers, unique bonuses, and awesome
                                gaming opportunities await.
                            </p>
                        </div>
                    </div>
                    <div className="sliderContent">
                        <div className="level-card">
                            <h3>04</h3>
                            <h4>
                                Elevate Your <span className="highlight">Status</span>
                            </h4>
                            <p>
                                Climb to higher Ranker levels! New offers, unique bonuses, and awesome
                                gaming opportunities await.
                            </p>
                        </div>
                    </div>
                    <div className="sliderContent">
                        <div className="level-card">
                            <h3>05</h3>
                            <h4>
                                Elevate Your <span className="highlight">Status</span>
                            </h4>
                            <p>
                                Climb to higher Ranker levels! New offers, unique bonuses, and awesome
                                gaming opportunities await.
                            </p>
                        </div>
                    </div>
                </Slider>
            </div>

            {/* <div className="navigation">
                <a href="#" className="nav-btn">❮</a>
                <a href="#" className="nav-btn">❯</a>
            </div> */}
        </div>
    );
};

export default ProcessLevels;
