import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";
import emailValidate from "services/emailValidate";
import LanguageSwitcher from "elements/LanguageSwitcher";
import api from "services/api";
import { Button, Modal } from "react-bootstrap";
import { __ } from "services/Translator";

const Footer = (props) => {
  const [showBonusModal, setShowBonusModal] = useState(false);
  const d = new Date();
  let year = d.getFullYear();

  useEffect(() => {
    setTimeout(() => {
      try {
        window.anj_8af40258_9bb6_4fba_ba6c_9a812f3a140a.init();
      } catch (ex) { }
    }, 2000);
  }, []);

  return (
    <>
      {!props.isAuthenticated && (
        <div className="present_area">
          <a href="/promotions">
            <img
              className="greenIcon"
              src={
                require("../assets/images/icons/present-icon-green.svg").default
              }
            />
            <img
              className="blueIcon"
              src={
                require("../assets/images/icons/present-icon-blue.svg").default
              }
            />
          </a>
        </div>
      )}

      <footer className="">
        <section className="footer">
          <div className="footer_grids">
            <div style={{ textAlign: "center" }} className="d-block d-md-none">
              <Link to="/">
                <img
                  style={{ width: "160px" }}
                  src={
                    require("../assets/images/logos/logo.png").default
                  }
                  alt=""
                />
              </Link>
            </div>
            <div className="links_section">
              {/* <div className="single_block d-none d-md-block">
                <ul></ul>
              </div> */}
              <div className="single_block linkEffect">
                <ul>
                  <li className="uppercase list_heading">
                    {__("information")}
                  </li>
                  <li>
                    <Link to="/about">
                      {__("About")} {__("Casino Zino")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/vip">{__("VIP")}</Link>
                  </li>
                  <li>
                    <Link to="/promotions">{__("Promotions")}</Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        props.setIsChatVisible(false);
                        props.setSupportSidebarOpen(true);
                        props.setShowPreLeftSidebar(false);
                      }}
                    >
                      {__("Support")}
                    </Link>
                  </li>

                  {props.isAuthenticated && (
                    <li>
                      <Link to="/store">{__("Store")}</Link>
                    </li>
                  )}
                  {props.isAuthenticated && (
                    <li>
                      <Link to="/rewards">{__("Rewards")}</Link>
                    </li>
                  )}
                  <li>
                    <a
                      target="_blank"
                      href="https://login.zinoaff.com/signup.php"
                    >
                      {__("Affiliates")}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="single_block linkEffect">
                <ul>
                  <li className="uppercase list_heading">{__("Legal Info")}</li>

                  <li>
                    <Link to="/terms-and-conditions">
                      {__("Terms and conditions")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">{__("Privacy policy")}</Link>
                  </li>
                  <li>
                    <Link to="/bonus-terms">{__("Bonus Terms")}</Link>
                  </li>
                  {/* <li>
                    <Link to="/aml-policy">{__("AML Policy")}</Link>
                  </li> */}
                  <li>
                    <Link to="/responsible-gaming">
                      {__("Responsible Gaming")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/aml-policy">{__("AML Policy")}</Link>
                  </li>
                </ul>
              </div>
              <div className="single_block linkEffect">
                <ul>
                  <li className="uppercase list_heading">{__("SERVICES")}</li>
                  {/* <li>
                    <Link to="/games/all"> {__("Casino Games")}</Link>
                  </li>                  
                  <li>
                    <Link to="/games/live-casino"> {__("Live Casino")}</Link>
                  </li>
                  <li>
                    <Link to="/games/live-casino"> {__("Virtual Games")}</Link>
                  </li>
                  <li>
                    <Link to="/games/live-casino"> {__("Popular Games")}</Link>
                  </li> */}
                </ul>
                <ul className="like_left_menu">
                  <li className={"slotsMenu "}>
                    <Link to="/games/slots">
                      <div className="icon">
                        {/* <img
                        className=""
                        src={
                          require("../assets/images/common/slots.svg").default
                        }
                      /> */}
                      </div>
                      <div className="text">{__("Slots")}</div>
                    </Link>
                  </li>
                  <li className={"liveCasinoMenu "}>
                    <Link to="/games/live-casino">
                      <div className="icon">
                        {/* <img
                        className=""
                        src={
                          require("../assets/images/common/live-casino.svg")
                            .default
                        }
                      /> */}
                      </div>
                      <div className="text">{__("Live Casino")}</div>
                    </Link>
                  </li>
                  <li className={"newGamesMenu "}>
                    <Link to="/games/new">
                      <div className="icon">
                        {/* <img
                        className=""
                        src={
                          require("../assets/images/common/new-games.svg")
                            .default
                        }
                      /> */}
                      </div>
                      <div className="text">{__("New Games")}</div>
                    </Link>
                  </li>
                  <li className={"casinoGamesMenu "}>
                    <Link to="/games/table-games">
                      <div className="icon">
                        {/* <img
                        className=""
                        src={
                          require("../assets/images/common/table-games.svg")
                            .default
                        }
                      /> */}
                      </div>
                      <div className="text">{__("Table Games")}</div>
                    </Link>
                  </li>
                  {/* <li className={"jackpotsMenu d-none"}>
                    <Link to="/games/jackpots">
                      <div className="icon">
                        <img
                        className=""
                        src={
                          require("../assets/images/common/jackpots.svg")
                            .default
                        }
                      /> 
                      </div>
                      <div className="text">{__("Jackpots")}</div>
                    </Link>
                  </li> */}
                  <li className="zinoExclusiveMenu d-none">
                    <Link to="/games/zino-exclusive">
                      <div className="icon"></div>
                      <div className="text">{__("Zino Exclusive")}</div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="single_block">
                <ul>
                  {/* <li className="uppercase list_heading">{__("company")}</li>
                  <li>When Lambo N.V.</li>
                  <li>Abraham de Veerstraat 9</li>
                  <li>Willemstad, Curacao</li>
                  <li>support@casinobull.io</li> */}
                  <li className="active_social_link">
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/zino_casino"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.facebook.com/Zino-Casino-114098383718493"
                        >
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.youtube.com/channel/UCmBwohaStUJLPtfvBarVi4g"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                    <br />
                    <br />
                    <br />
                    <Link to="/">
                      <img
                        style={{ width: "160px" }}
                        src={
                          require("../assets/images/logos/logo.png")
                            .default
                        }
                        alt=""
                      />
                    </Link>
                    {/* <li>
                      <a target="_blank" href="https://twitter.com/">
                        <i className="fab fa-google-plus-g"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://twitter.com/">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li> */}

                    <div className="socialLinkContainger d-none">
                      <Link to="#">
                        <i className="fab fa-facebook"></i>
                      </Link>

                      <Link to="#">
                        <i className="fab fa-twitter"></i>
                      </Link>

                      <Link to="#">
                        <i className="fab fa-google-plus-g"></i>
                      </Link>
                      <Link to="#">
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </div>
                  </li>
                </ul>
                <br />
                <br />
                <Link
                  className="common_btn reversed_common_btn bonusBtn float-left d-none"
                  onClick={() => {
                    setShowBonusModal(true);
                  }}
                >
                  <span className="dollerIcons">
                    <img
                      src={
                        require("../assets/images/icons/dollar-white.svg")
                          .default
                      }
                    />
                  </span>
                  <span className="bonusText">{__("Bonus")}</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="copyright_section">
            <div className="left"></div>
            <div className="right">
              <div className="brands ">
                <ul>
                  <li className="dotIgamingLogo d-none">
                    <a target="_blank" href="https://dot-igaming.com">
                      <img
                        className="solidImg brand_img"
                        src={
                          require("../assets/images/common/dot-igaming-solid-white.svg")
                            .default
                        }
                      />
                      <img
                        className="hoverImg brand_img"
                        src={
                          require("../assets/images/common/dot-igaming.svg")
                            .default
                        }
                      />
                    </a>
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={require("../assets/images/brands/vivo.svg").default}
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/bsg-logo-2019.svg")
                          .default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      style={{ height: "64px", marginTop: "-17px" }}
                      src={
                        require("../assets/images/brands/playson.svg").default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      style={{ height: "46px", marginTop: "-8px" }}
                      src={require("../assets/images/brands/leap.svg").default}
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      style={{ height: "80px", marginTop: "-26px" }}
                      src={
                        require("../assets/images/brands/spinomenal.svg")
                          .default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      style={{ height: "55px", marginTop: "-13px" }}
                      src={
                        require("../assets/images/brands/tom-horn-gaming.svg")
                          .default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/red-rake-gaming-casino.svg")
                          .default
                      }
                    />
                  </li>
                </ul>
                <ul>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/visa-logo.svg").default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/mastercard-logo.svg")
                          .default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/bank-transfer-logo.svg")
                          .default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/verified-by-visa-logo.svg")
                          .default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/bitcoin.svg").default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/ethereum.svg").default
                      }
                    />
                  </li>
                  <li>
                    <img
                      className="brand_img"
                      src={
                        require("../assets/images/brands/18-logo.svg").default
                      }
                    />
                  </li>

                  <li>
                    <a target="_blank" href="https://www.gamstop.co.uk">
                      <img
                        className="brand_img"
                        src={
                          require("../assets/images/brands/gam-stop-logo.svg")
                            .default
                        }
                      />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="copyright_content">
                <div className="dotIgamingLogo other_logos">

                  <a target="_blank" href="https://dot-igaming.com">
                    {/* <img
                          className="hoverImg brand_img"
                          src={
                            require("../assets/images/common/dot-igaming-solid-white.svg")
                              .default
                          }
                        />
                        <img
                          className="solidImg brand_img"
                          src={
                            require("../assets/images/common/dot-igaming.svg")
                              .default
                          }
                        /> */}
                    <svg
                      className="dotiGamingLogo"
                      id="b8bfea57-ddb9-4253-92bc-61874563b518"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 3500 1500"
                    >
                      <title>dotiGaming copy 2SVG</title>
                      <path d="M519.2,1291.1a125.6,125.6,0,0,1-109.9-63.5q-16.7-29.2-16.7-66t16.9-65.1a125.4,125.4,0,0,1,46.2-46.1c19.4-11.3,41-16.9,64.5-16.9a129.3,129.3,0,0,1,39.8,6.1,117.9,117.9,0,0,1,35.3,18.4V943.6l49.1-9.3v352.9H595.8v-24Q563.9,1291.1,519.2,1291.1Zm6.4-42.7a97.7,97.7,0,0,0,39.5-7.8,82.8,82.8,0,0,0,30.2-22.1V1105.6c-7.8-9.5-17.9-16.8-30.2-22.1a99.8,99.8,0,0,0-39.5-7.8c-15.7,0-30,3.6-42.9,11a79.6,79.6,0,0,0-30.5,30.7c-7.3,13.1-11,27.8-11,44.2s3.7,31,11,44.1a83,83,0,0,0,30.5,31.2A82.1,82.1,0,0,0,525.6,1248.4Z" />
                      <path d="M821.1,1292.1q-36.3,0-66-16.9a126.1,126.1,0,0,1-47.1-46.6q-17.6-29.7-17.5-66.1c0-24.5,5.8-46.5,17.2-66a129.9,129.9,0,0,1,47.1-46.6q30-17.4,66.3-17.4t66,17.4a130.1,130.1,0,0,1,46.9,46.6c11.4,19.5,17.2,41.5,17.2,66s-5.8,46.3-17.2,66.1a124.9,124.9,0,0,1-46.9,46.6Q857.4,1292.2,821.1,1292.1Zm.5-43.2a76.7,76.7,0,0,0,41.2-11.5,82.7,82.7,0,0,0,29.5-31.2q10.8-19.7,10.8-44.1t-10.8-43.5a85.7,85.7,0,0,0-29.5-31.2,79.8,79.8,0,0,0-83.2,0,85.4,85.4,0,0,0-29.7,31.2q-10.8,19.4-10.8,43.5t10.8,44.1a82.4,82.4,0,0,0,29.7,31.2A78.9,78.9,0,0,0,821.6,1248.9Z" />
                      <path d="M1090.1,1291.6c-23.9,0-42.2-5.5-55-16.7s-19.1-27.6-19.1-49.5V1078.6H963v-41.2h53V974.1l48.6-12.3v75.6h74.1v41.2h-74.1v136c0,12.4,2.8,21.3,8.5,26.7s15.2,8.1,28.3,8.1a102.2,102.2,0,0,0,18.4-1.5,96.3,96.3,0,0,0,17.4-4.9v41.8a113.1,113.1,0,0,1-22.8,5.1A177.6,177.6,0,0,1,1090.1,1291.6Z" />
                      <path d="M1206.9,1291.1a30.9,30.9,0,0,1-30.9-30.9,31.2,31.2,0,0,1,9.1-22.6,30.2,30.2,0,0,1,43.6,0,31.2,31.2,0,0,1,9.1,22.6,30.9,30.9,0,0,1-30.9,30.9Z" />
                      <path d="M1321.7,996.6a28.9,28.9,0,0,1-21.1-8.8,29.7,29.7,0,0,1-8.8-21.6,30.2,30.2,0,0,1,51.5-21.1,29.4,29.4,0,0,1,8.9,21.6,30.2,30.2,0,0,1-30.5,29.9Zm-24.5,290.6V1037.4h49.1v249.8Z" />
                      <path d="M1575,1292.6q-37.8,0-70.4-13.7a180.2,180.2,0,0,1-57.2-38.1,176.2,176.2,0,0,1-38.3-56.4c-9.2-21.4-13.7-44.4-13.7-69a176,176,0,0,1,13.5-68.9,171.6,171.6,0,0,1,38-56.5A179.9,179.9,0,0,1,1574,938.2a193.3,193.3,0,0,1,77.4,15.7,171.9,171.9,0,0,1,61.1,44.2l-35.4,33.9a125,125,0,0,0-46.1-34.4,141.9,141.9,0,0,0-57-11.8,124.9,124.9,0,0,0-89.8,37.3,127.2,127.2,0,0,0-26.7,41.3,140.5,140.5,0,0,0,0,102.1,128.7,128.7,0,0,0,26.7,41.4,121,121,0,0,0,40.5,27.8,128.2,128.2,0,0,0,49.8,9.8q30.9,0,55.7-12.8a110,110,0,0,0,59.7-85.9H1576v-44.6h167.9v18.1a178.6,178.6,0,0,1-12.8,67.8,170.4,170.4,0,0,1-35.6,54.9,161.2,161.2,0,0,1-53.7,36.6Q1610.9,1292.7,1575,1292.6Z" />
                      <path d="M1871.5,1291.6c-18.3,0-34.4-3.2-48.1-9.5s-24.6-15.3-32.4-26.8-11.8-24.7-11.8-39.7q0-35.4,27.3-55.5t73.8-20.1c24.6,0,47.1,4.4,67.8,13.2v-26q0-27-15.3-40.2c-10.1-8.9-25.2-13.3-45.1-13.3a129.4,129.4,0,0,0-35.6,5.2c-12.3,3.4-26.1,8.7-41.5,15.9L1792,1058q27.9-13.2,52.7-19.4a207.1,207.1,0,0,1,49.9-6.1q48.6,0,75.3,23t26.7,65.8v165.9h-48.5v-21.6a110.5,110.5,0,0,1-35.4,19.7A130.8,130.8,0,0,1,1871.5,1291.6Zm-45.7-77.5c0,12.4,5.3,22.4,15.7,29.9s24.1,11.3,40.8,11.3a108.8,108.8,0,0,0,36.3-5.9,82.6,82.6,0,0,0,29.5-18.1V1189a116.5,116.5,0,0,0-29.5-11.7,135.8,135.8,0,0,0-33.9-4c-18.3,0-32.7,3.8-43.2,11.3S1825.8,1202,1825.8,1214.1Z" />
                      <path d="M2053.1,1287.2V1037.4h49.1v23.5q27.9-28.4,70.2-28.4c17,0,32.2,3.7,45.6,11.3a81.1,81.1,0,0,1,31.4,30.9c10.2-14.1,22.4-24.6,36.6-31.7s30.2-10.5,47.9-10.5,34.9,4.1,48.8,12.2a85.9,85.9,0,0,1,32.6,34.2q11.8,21.8,11.8,51.2v157.1h-48.6V1138.5q0-29.4-15.4-46.4c-10.3-11.3-24.2-16.9-41.5-16.9-12.4,0-23.6,2.8-33.4,8.6s-18.3,14.1-25.5,25.2c.6,3.3,1.1,6.7,1.5,10.3s.5,7.2.5,10.8v157.1h-48.6V1138.5q0-29.4-15.8-46.4c-10.4-11.3-24.2-16.9-41.2-16.9-12.1,0-22.9,2.5-32.4,7.6s-17.6,12.7-24.5,22.8v181.6Z" />
                      <path d="M2508.6,996.6a28.7,28.7,0,0,1-21.1-8.8,29.3,29.3,0,0,1-8.8-21.6,30,30,0,0,1,29.9-29.9,30.1,30.1,0,0,1,30.4,30.4,28.7,28.7,0,0,1-8.8,21.1A29.3,29.3,0,0,1,2508.6,996.6Zm-24.5,290.6V1037.4h49v249.8Z" />
                      <path d="M2591,1287.2V1037.4h49.1v25.5c19.3-20.3,44.4-30.4,75.1-30.4,19.3,0,36.3,4.1,50.8,12.2a88.9,88.9,0,0,1,34.4,34.4q12.4,22.1,12.5,51v157.1h-49.1V1138.5c0-19.6-5.5-35.1-16.7-46.4s-26.3-16.9-45.6-16.9c-13.1,0-24.8,2.8-35.1,8.6a76.9,76.9,0,0,0-26.3,24.3v179.1Z" />
                      <path d="M2992.5,1392.3a224.8,224.8,0,0,1-53-6.4,189.1,189.1,0,0,1-47.1-18.2l19.2-38.3a221.8,221.8,0,0,0,40.9,16,151.7,151.7,0,0,0,38.6,5.1q33.3,0,50.3-14.7t16.9-44.2v-28.4q-32.4,26-75.1,26-34.8,0-63.3-17.2a127.9,127.9,0,0,1-45.4-46.4c-11.3-19.4-16.9-41-16.9-64.5s5.7-45.6,17.1-65.1a129.2,129.2,0,0,1,45.9-46.1c19.2-11.3,40.5-16.9,64.1-16.9a124.2,124.2,0,0,1,39.5,6.3,118.1,118.1,0,0,1,35.1,19.2v-21.1h48.1v253.7q0,49.2-29.4,75.1C3058.3,1383.6,3029.9,1392.3,2992.5,1392.3Zm-2.9-145.3a94.7,94.7,0,0,0,39-7.9,79.8,79.8,0,0,0,29.7-21.6V1104.6c-7.5-8.8-17.3-15.8-29.4-21.1a98.5,98.5,0,0,0-39.3-7.8c-15.7,0-29.9,3.6-42.5,11a81.5,81.5,0,0,0-29.9,30.4c-7.4,13-11,27.6-11,44s3.6,30.5,11,43.4a84.4,84.4,0,0,0,30.2,30.9A80.2,80.2,0,0,0,2989.6,1247Z" />
                      <path d="M1858.5,423.4a177.2,177.2,0,0,1,106.5-2.3c-7.3,116.3-83.3,222-200.5,261.4l-2.3.8a177.5,177.5,0,0,1-15.7-34.2C1715.1,556,1765.4,454.7,1858.5,423.4Z" />
                      <path d="M1950,308.7a294.2,294.2,0,0,1,15.2,80.2,208,208,0,0,0-116.7,4.9c-109.3,36.8-168.4,155.9-131.6,265.3a207.2,207.2,0,0,0,14.2,32.6c-144.4,30.5-292.1-51.3-340.3-194.6-52.1-154.4,30.9-321.8,185.4-373.8S1898,154.3,1950,308.7Z" />
                      <path d="M1965.3,388.9a313.9,313.9,0,0,1-.3,32.2,178.1,178.1,0,0,1,119.2,114.3c31.4,93.1-18.8,194.3-112,225.7-80.9,27.3-168.1-7.2-210-77.8a298.8,298.8,0,0,1-31.1,8.4c47.5,88.1,153.2,131.9,251.1,99,109.4-36.9,168.4-155.9,131.6-265.3A209.5,209.5,0,0,0,1965.3,388.9Z" />
                    </svg>
                  </a>

                </div>

                <div className="other_logos">
                  <img
                    alt=""
                    className="provably_fair_img"
                    src={
                      require("../assets/images/footer/provably-fair-gr.svg")
                        .default
                    }
                  />
                </div>

                <div className="other_logos">
                  <img
                    alt=""
                    className="responsible_gaming_img"
                    src={
                      require("../assets/images/footer/responsible-gaming-gr.svg")
                        .default
                    }
                  />
                </div>
                <div className="other_logos">
                  <img
                    className="plus_age_img"
                    alt=""
                    src={
                      require("../assets/images/footer/18-plus-age-gr.svg")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="license_and_info_text">
                <div className="license_seal ">
                  {/* <iframe
                    style={{
                      width: "150px",
                      height: "50px",
                      border: "none",
                      margin: " 20px 0",
                      // marginLeft: "64px",
                      verticalAlign: "bottom",
                    }}
                    src="https://licensing.gaming-curacao.com/validator/?lh=1de65c27b5de9463e7d0ae6327560c65&template=tseal"
                  ></iframe> */}
                  <div
                    id="anj-8af40258-9bb6-4fba-ba6c-9a812f3a140a"
                    data-anj-seal-id="8af40258-9bb6-4fba-ba6c-9a812f3a140a"
                    data-anj-image-size="128"
                    data-anj-image-type="basic-small"
                    style={{
                      width: "75px",
                      // height: "50px",
                      border: "none",
                      margin: " 20px auto",
                      // marginLeft: "64px",
                      verticalAlign: "bottom",
                    }}
                  ></div>
                </div>
                <div className="company_info">
                  {__(
                    "Zino Casino is operated by Zino Technologies N.V., a company incorporated in Curacao with company registration number 161659 and having its registered address at Abraham de Veerstraat 9, Willemstad, Curacao."
                  )}
                  <br />
                  {__(
                    "Zino Casino is licensed and regulated by the Gaming Authority of Curacao under license number NEW-LICENSE123452022"
                  )}
                </div>
              </div>
              <div className="copyright_text">
                {__("All rights reserved")} {year} © {__("Zino Casino")}
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
