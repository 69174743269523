import React from 'react';


const VIPClub = () => {
    return (
        <div className="vip-club-container">
            <h2 className="vipHeading">About <span>VIP Club</span></h2>
            <p className="vip-club-description">
                Elevated Elite Status - Genie offers top-tier advantages. Stick with Genie Jackpot for the finest play experience and superior service quality! Membership in the VIP club grants you:
            </p>

            <div className="vip-club-card">
                <h3 className="vip-club-subtitle">Being a member of the VIP club means that you have access to:</h3>

                <div className="vip-club-benefits">
                    <span className="vip-benefit dedicated">Dedicated VIP Manager</span>
                    <span className="vip-benefit exclusive">Exclusive Information</span>
                    <span className="vip-benefit enhanced">Enhanced Rebate Rewards</span>
                    <span className="vip-benefit vipExclusive">VIP Exclusive Bonus</span>
                    <span className="vip-benefit additional">Additional Special Benefits</span>
                    <img alt='' className='benefits-image' src={require('assets/images/vip/slot-banner.webp').default} />
                </div>



                <p className="vip-club-info">
                    Demonstrate active gaming engagement to receive your VIP Club invite. Further information available on our VIP Telegram channel.
                </p>
            </div>

            <button className="vip-club-button">
                Join
            </button>
        </div>
    );
};

export default VIPClub;