import React from "react";


const RankSystem = () => {
    return (
        <div className="rank-system">
            <h2 className="vipHeading">
                A Lot Of Benefits With <span>Rank System</span>
            </h2>
            <div className="content">
                <div className="benefit boost">
                    <img src={require('assets/images/vip/boosted-rebates.png').default} alt="Boosted Rebates" />
                    <div className="benefitContent">
                        <h3>Boosted Rebates</h3>
                        <p>Get back up to 25% of your coins!</p>
                    </div>
                </div>
                <div className="benefit vip">
                    <img src={require('assets/images/vip/vip-circle.png').default} alt="VIP Circle" />
                    <div className="benefitContent">
                        <h3>VIP Circle</h3>
                        <p>Join the VIP Club for unique bonuses!</p>
                    </div>
                </div>
                <div className="roulette">
                    <img src={require('assets/images/vip/vip-banner.webp').default} alt="Roulette" />
                </div>
                <div className="rouletteShadow"></div>
                <div className="benefit tiers">
                    <img src={require('assets/images/vip/tiers-treats.png').default} alt="Tiers & Treats" />
                    <div className="benefitContent">
                        <h3>Tiers & Treats</h3>
                        <p>Play more, earn more!</p>
                    </div>
                </div>
                <div className="benefit special">
                    <img src={require('assets/images/vip/special-offers.png').default} alt="Special Offers" />
                    <div className="benefitContent">
                        <h3>Special Offers</h3>
                        <p>Ascend ranks to access one-of-a-kind advantages!</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RankSystem;
