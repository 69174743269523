import React from "react";


const VipLevels = () => {
    return (
        <div className="vip-levels">
            <h2 className="vipHeading">
                Flow to 20 <span >VIP Levels</span>
            </h2>
            <p className="description">
                Climb the Zino Ranks and get amazing perks during your casino journey. There are 5 major ranks, and each
                major rank has five sub-ranks. Each time you climb, you get better and more exciting rewards! Check the ranks
                below to see what is possible. Let the magic begin...
            </p>

            <ul className="rank-list">
                <li>**Genie's Apprentice**: This is the initial rank where players embark on their journey, symbolizing their humble beginnings as they learn the ropes of Genie Jackpots.</li>
                <li>**Wish Seeker**: As players progress and gain experience, they ascend to the "Wish Seeker" rank, reflecting their growing ambition to unlock the Genie's hidden treasures.</li>
                <li>**Mystic Alchemist**: This rank represents players who have honed their skills and are now adept at transforming bets into winnings, much like an alchemist with the Genie's magic.</li>
                <li>**Sultan of Riches**: Players reaching this level have proven themselves as true Genie masters, accumulating substantial wealth and riches through their gameplay.</li>
                <li>**Eternal Genie**: The highest achievable rank in your casino, "Eternal Zino," signifies the pinnacle of expertise, where players have harnessed the Genie's magic to achieve enduring success and legendary status within your crypto casino.</li>
            </ul>
            <div className="rank-table-container">
                <div className="rank-table">
                    <div className="tableColumn userLevelColumn">
                        <div className="columnHeading">
                            <div className="userPhoto"></div>
                            User Name
                        </div>
                        {[...Array(5)].map((_, index) => (
                            <div className="user-level">Level {index + 1}</div>
                        ))}
                    </div>
                    <div className="tableColumn">
                        <div className="columnHeading">
                            <img className="icon" alt="Bronze Icon" src={require('assets/images/vip/bronze-icon.webp').default} /> <br />
                            Bronze
                        </div>
                        {[...Array(5)].map((_, index) => (
                            <div className="user-level">0%</div>
                        ))}
                    </div>
                    <div className="tableColumn">
                        <div className="columnHeading">
                            <img className="icon" alt="Silver Icon" src={require('assets/images/vip/silver-icon.webp').default} /><br />
                            Silver
                        </div>
                        {[...Array(5)].map((_, index) => (
                            <div className="user-level">0%</div>
                        ))}
                    </div>
                    <div className="tableColumn">
                        <div className="columnHeading">
                            <img className="icon" alt="gold Icon" src={require('assets/images/vip/gold-icon.webp').default} /><br />
                            Gold
                        </div>
                        {[...Array(5)].map((_, index) => (
                            <div className="user-level">0%</div>
                        ))}
                    </div>
                    <div className="tableColumn">
                        <div className="columnHeading">
                            <img className="icon" alt="platinum Icon" src={require('assets/images/vip/platinum-icon.webp').default} /><br />
                            Platinum
                        </div>
                        {[...Array(5)].map((_, index) => (
                            <div className="user-level">0%</div>
                        ))}
                    </div>
                    <div className="tableColumn">
                        <div className="columnHeading">
                            <img className="icon" alt="platinum Icon" src={require('assets/images/vip/diamond-icon.webp').default} /><br />
                            Diamond
                        </div> 
                        {[...Array(5)].map((_, index) => (
                            <div className="user-level">0%</div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VipLevels;
