import React from "react";

class SpinnerComponent extends React.Component {
  state = {
    loading: true,
  };
  render() {
    return (
      <div className="spinner_overlay">
        <img
          className="spinner"
          src={require("assets/images/logos/logo.png").default}
        />
      </div>
    );
  }
}

export default SpinnerComponent;
