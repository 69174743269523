import React, { useState, useEffect, useRef, lazy } from "react";
import { Redirect, Route, Link, useHistory, useParams, useLocation } from "react-router-dom";

import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "local-storage";
import { Badge, Table, Collapse, Modal } from "react-bootstrap";

import CategoryGameSlider from "elements/games_elements/CategoryGameSlider";
import GameSlider from "elements/games_elements/GameSlider";
import CategoryGames from "elements/games_elements/CategoryGames";
import SingleCategoryGames from "elements/games_elements/SingleCategoryGames";
import GamesFilter from "elements/games_elements/GamesFilter";
import Slider from "react-slick";
import Banner from "elements/Banner";
import FAQ from "elements/FAQ";
import BannerMobile from "elements/BannerMobile";
import SearchBox from "elements/SearchBox";

import Winners from "elements/Winners";
import { __ } from "services/Translator";

export default function Landing(props) {
  const params = useParams();
  const [gameList, setGameList] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const closeAllModals = () => {
    history.push("/");
  };

  const justActivated = props.activated ? true : false;

  const [gameSorting, setGameSorting] = useState("Most Popular");
  const [pagination, setPagination] = useState(null);
  const [gameLoaded, setGameLoaded] = useState(0);
  const [totalGame, setTotalGame] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const getGameList = async (page) => {
    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      game_types: ["all"],
      page: page,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      props.getProviders("all");
      setGameList(response.data.games?.all?.gamelist ?? []);

      if (page === 1) {
        setGameList(response.data.games?.all?.gamelist ?? []);
      } else {
        var gamesClone = JSON.parse(JSON.stringify(gameList));
        console.log("gamesClone", gamesClone);
        gamesClone = [...gamesClone, ...response.data.games["all"].gamelist];
        setGameList(gamesClone);
      }

      setGameLoaded(page === 1 ? response.data.loadedGameCount : gameLoaded + response.data.loadedGameCount);
      setTotalGame(response.data.gameCount);

      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }
    }
  };

  const openLogin = () => {
    props.setModalLoginPropsData(true);
    history.push("/");
    setTimeout(function () {
      props.setModalLoginPropsData(false);
    }, 500);
  };

  const onPageChange = (page) => {
    getGameList(page);
  };

  useEffect(() => {
    getGameList(1);
  }, [gameSorting, props.selectedProviders]);

  // expand searcbar

  const [searchStr, setSearchStr] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const searchTextBox = useRef(null);

  const [expandSearchBar, setExpandSearchBar] = useState(false);

  const toggleExpandedSearchBar = (force) => {
    if (expandSearchBar) {
      setSearchSuggestions([]);
      if (force === true) {
        setSearchStr("");
        setExpandSearchBar(false);
      } else {
        if (searchStr.length === 0) {
          setExpandSearchBar(false);
        }
      }
    } else {
      setExpandSearchBar(true);
      searchTextBox.current.focus();
    }
  };

  const getSearchSuggestions = async () => {
    if (searchStr.length >= 2) {
      var response = await api.get("/games/search-suggestions", {
        query: searchStr,
        providers: props.selectedProviders,
        extensiveInfo: true,
      });
      console.log("getSearchSuggestions", response.data);
      if (response.status === 200) {
        setSearchSuggestions(response.data);
      }
    } else {
      setSearchSuggestions([]);
    }
  };

  useEffect(() => {
    getSearchSuggestions();
  }, [searchStr]);

  const doSearchByGameName = (text) => {
    setSearchStr("");
    props.setSearchStr(text);
    setSearchSuggestions([]);
    toggleExpandedSearchBar(true);
    history.push("/games/search");
  };

  const [visible, setVisible] = useState(true);
  const hideNoImageFoundGames = () => {
    setVisible(false);
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [searchFocus, setSearchFocus] = useState(false);
  useEffect(() => {
    console.log("PROPS", props);
    if (props.location.state && props.location.state.focusSearch) {
      setSearchFocus(true);
      document.getElementById("searchFocusInput").focus();
    } else {
      setSearchFocus(false);
    }
  }, [params]);

  //Newsletter section
  const [subscribeEmail, setSubscribeEmail] = useState("");

  const newsletterSubscription = async (ev) => {
    ev.preventDefault();
    var valid = true;
    if (subscribeEmail.length < 2 || subscribeEmail.length > 250) {
      props.showAlert("Warning!!", __("Please enter your email address"));
      valid = false;
    }
    if (!emailValidate(subscribeEmail)) {
      props.showAlert("Warning!!", __("Please enter your email address"));
      valid = false;
    }

    if (valid) {
      var response = await api.post("/newsletter-subscription", {
        subscribeEmail: subscribeEmail,
      });

      if (response.status === 200) {
        props.showAlert("Success!!", response.data.message);
        setSubscribeEmail("");
      } else {
        props.showAlert("Warning!!", response.data.message, true);
      }
    }
  };

  useEffect(() => {
    if (location.pathname == "/support") {
      props.setSupportSidebarOpen(true);
    }
  }, []);

  const checkIframe = () => {
    if (window.parent.parent.location.href != window.location.href) {
      window.parent.location.href = window.location.href;
    } else {
    }
  };

  useEffect(async () => {
    checkIframe();
  }, []);

  const [staticBanners, setStaticBanners] = useState([]);
  const getStaticBanners = async (ev) => {
    var response = await api.get("/homepage-images");
    setStaticBanners(response.data);
    // console.log(setStaticBanners, response.data[2].image);
  };

  useEffect(() => {
    getStaticBanners();
  }, []);

  return (
    <div className="landing">
      <>
        <Banner {...props} />
        {/* {!props.isMobileDevice && <Banner {...props} />} */}
        {/* {props.isMobileDevice && <BannerMobile {...props} />} */}
      </>

      <div className="category_menu_and_search_area ">
        <div className="category_menu">
          <GamesFilter {...props} />
        </div>
        {/* <SearchBox {...props} /> */}
      </div>

      <div className="zino_category_menu d-none ">
        <div className="common_headline_with_bg">{__("Game Categories")}</div>
        <ul>
          <li>
            <div className="categoryMenu">
              <Link
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
                to="/games/slots"
              >
                <img alt="" className="categoy_icon" src={require("../assets/images/common/slots-icon.png").default} />
              </Link>
              <Link
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
                to="/games/slots"
                className="bottom_text_area"
              >
                <div className="category_name">{__("Slots")}</div>
              </Link>
            </div>
          </li>
          <li>
            <div className="categoryMenu">
              <Link
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
                to="/games/virtual-games"
              >
                <img
                  alt=""
                  className="categoy_icon"
                  src={require("../assets/images/common/virtual-icon.png").default}
                />
              </Link>
              <Link
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
                to="/games/virtual-games"
                className="bottom_text_area"
              >
                <div className="category_name"> {__("Virtual Games")} </div>
              </Link>
            </div>
          </li>
          <li>
            <div className="categoryMenu">
              <Link
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
                to="/games/live-casino"
              >
                <img
                  alt=""
                  className="categoy_icon"
                  src={require("../assets/images/common/live-casino-icon.png").default}
                />
              </Link>
              <Link
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
                to="/games/live-casino"
                className="bottom_text_area"
              >
                <div className="category_name">{__("Live Casino")}</div>
              </Link>
            </div>
          </li>
          <li>
            <div className="categoryMenu">
              <Link
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
                to="/games/popular"
              >
                <img
                  alt=""
                  className="categoy_icon"
                  src={require("../assets/images/common/popular-games-icon.png").default}
                />
              </Link>
              <Link
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
                to="/games/popular"
                className="bottom_text_area"
              >
                <div className="category_name">{__("Popular Games")}</div>
              </Link>
            </div>
          </li>
        </ul>
      </div>

      {/* <h3 className="common_headline_with_bg">
        +2000 Slots
      </h3> */}
      <CategoryGames
        {...props}
        gameList={gameList}
        categoryName={__("")}
        category="all"
        setGameSorting={setGameSorting}
        gameSorting={gameSorting}
        showPagination={true}
        pagination={pagination}
        onPageChange={onPageChange}
        gameLoaded={gameLoaded}
        totalGame={totalGame}
      />

      {!props.isMobileDevice && (
        <>
          {/* <SingleCategoryGames
            categoryName="Popular"
            category="popular"
            perPage="9"
          />

          <SingleCategoryGames
            categoryName="Slots"
            category="video_slots"
            perPage="9"
          />

          <SingleCategoryGames
            categoryName="Table Games"
            category="table_games"
            perPage="9"
          /> */}

          {/*}<SingleCategoryGames
            categoryName="Jackpot Games"
            category="jackpot"
            perPage="9"
          />*/}
        </>
      )}

      {props.isMobileDevice && (
        <>
          {/* <SingleCategoryGames
            categoryName={__("Popular Slots")}
            category="popular"
            perPage="5"
          />
          <SingleCategoryGames
            categoryName="Live Casino"
            category="live_casino"
            perPage="5"
          />
          <SingleCategoryGames
            categoryName="Table Games"
            category="table_games"
            perPage="5"
          /> */}
        </>
      )}
      <GameSlider {...props} categoryName="Live Casino" />
      <GameSlider {...props} categoryName="Jackpot Games" />
      {!props.isMobileDevice && (
        <>
          <br />
          <CategoryGameSlider staticBanners={staticBanners} {...props} />
        </>
      )}
      <Winners {...props} />

      <div className="night_banner_area container">
        {props.isAuthenticated ? <>
          <a href={staticBanners[2]?.link} className="single_night_banner ">
            <div className="row">
              <div className="col-md-6 col-lg-4 align-self-center">
                <div className="night_banner_text">
                  <div className="heading_text">{staticBanners[2]?.title}</div>
                  <div className="info_text">{staticBanners[2]?.description}</div>

                  <img style={{ width: "160px" }} src={require("../assets/images/logos/logo.png").default} alt="" />
                </div>
              </div>
              <div className="col-md-6 col-lg-8">
                {staticBanners != "" && <img className="night_banner" src={staticBanners[2]?.image} alt="" />}
              </div>
            </div>
          </a>


        </> : <>

          <a href="javascript:" onClick={() => {
            props.setModalLoginPropsData(true);
            setTimeout(function () {
              props.setModalLoginPropsData(false);
            }, 500);
          }} className="single_night_banner ">
            <div className="row">
              <div className="col-md-6 col-lg-4 align-self-center">
                <div className="night_banner_text">
                  <div className="heading_text">{staticBanners[2]?.title}</div>
                  <div className="info_text">{staticBanners[2]?.description}</div>

                  <img style={{ width: "160px" }} src={require("../assets/images/logos/logo.png").default} alt="" />
                </div>
              </div>
              <div className="col-md-6 col-lg-8">
                {staticBanners != "" && <img className="night_banner" src={staticBanners[2]?.image} alt="" />}
              </div>
            </div>
          </a>
        </>}



      </div>
      <div className="line_border center_align d-none">
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>

      <div className="footer_newsletter d-none">
        <div className="back_to_top">
          {__("BACK TO TOP")}{" "}
          <span>
            <Link to="#">
              <i className="fa fa-long-arrow-up" aria-hidden="true"></i>
            </Link>
          </span>
        </div>
        <div className="clearfix"></div>
        <br />
        <br />
        {!props.isAuthenticated && (
          <div className="newsletter_content">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="left_content">
                  {/* <div className="block_title">
                        # NEWSLETTER
                        <br />
                        <span className="dash">
                          <i className="fal fa-horizontal-rule"></i>
                        </span>
                      </div> */}
                  <div className="block_heading">
                    <div className="headline">{__("Yes! Send me monthly promotions!")}</div>
                    <div className="paragraph white">
                      {__("Sign up to receive free play promotions and prizes! We respect your privacy.")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <form onSubmit={newsletterSubscription}>
                  <div className="left_content">
                    <input
                      type="text"
                      value={subscribeEmail}
                      onChange={(ev) => setSubscribeEmail(ev.target.value)}
                      className="form-control subscribe_input"
                      placeholder={__("YOUR EMAIL")}
                    />
                    <button type="submit" className="common_btn  uppercase no_margin">
                      {__("SUBSCRIBE")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* <FAQ {...props} /> */}

      <Modal className="gamePlayMsgModal" show={justActivated} onHide={closeAllModals}>
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {__("Welcome")}!
          </h5>
        </Modal.Header>
        <Modal.Body className="login_modal forgot_pass">
          <div className="game_message_content">
            <i className="fas fa-check" style={{ color: "green", fontSize: "100px" }}></i>
            <br />
            {__("Your account is now activated. You can now login.")}
            <br />
            {__("Have fun!")}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="common_btn" onClick={openLogin}>
            {__("OK")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
