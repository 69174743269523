import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import DOMPurify from "dompurify";
import AppContext from "contexts/AppContext";
import ls from "services/ls";

import Translator, { __ } from "services/Translator";

export default function Promotions(props) {
  const isAuthenticated = props.isAuthenticated;
  const [pageContent, setPageContent] = useState({});
  const [promotions, setPromotions] = useState([]);

  const getResults = async () => {
    var response = await api.get("/promotions");
    // props.setBreadcrumb([]);
    if (response.status === 200) {
      setPageContent(response.data.content_data);
      setPromotions(response.data.promotions);
      // props.setBreadcrumb([{ name: response.data.content_data.page_title }]);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  const toggleExpanded = (index) => {
    var promotionsClone = JSON.parse(JSON.stringify(promotions));
    var prev = promotionsClone[index].expanded;
    promotionsClone.forEach((el) => {
      el.expanded = false;
    });
    promotionsClone[index].expanded = !prev;
    setPromotions(promotionsClone);
  };

  const [selectedLanguage, setSelectedLanguage] = useState({});

  const getSelectedLanguage = async () => {
    var lang = await Translator.getSelectedLanguage();
    setSelectedLanguage(lang);
    // updateRegistrationForm("language", lang.code);
  };

  useEffect(() => {
    getResults();
    getSelectedLanguage();
  }, []);

  //video palyer
  const [autoplay, setAutoplay] = useState(true);

  return (
    <>
      <section className="content_section container">
        <div className="promotion_area">
          <div className="common_heading_area">
            <h1 className="common_headline_with_bg float-left">
              {pageContent.page_title ? pageContent.page_title : ""}
            </h1>

            <div className="clearfix"></div>
          </div>

          <div className="promotions">
            {/* <h5>{__("Welcome Bonuses")}</h5> */}
            {/* <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(pageContent.content),
              }}
            ></p> */}
            <div className="row">
              {promotions.map((promotion, index) => {
                var details = DOMPurify.sanitize(promotion.details);
                if (details.length > 100) {
                  var shortText = DOMPurify.sanitize(
                    promotion.details.substr(0, 100) + "..."
                  );
                } else {
                  var shortText = details;
                }
                var showMore = true;
                var showMoreBtn = true;
                if (details === shortText) {
                  showMore = false;
                }

                return (
                  <div className="col-md-6 single-promotion" key={index}>
                    <img src={promotion.image} alt="t1.jpg" />
                    <h5>{promotion.title}</h5>

                    <div className="promotion_main_text_area">
                      <div
                        className={
                          "promotion_main_text " +
                          (promotion.expanded ? "open" : "")
                        }
                      >
                        {showMore ? (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: promotion.expanded
                                  ? details
                                  : shortText,
                              }}
                            ></span>
                          </>
                        ) : (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: details,
                            }}
                          ></span>
                        )}
                        {showMore && (
                          <a
                            className="lessMoreBtn"
                            onClick={() => toggleExpanded(index)}
                          >
                            {promotion.expanded ? __("Less") : __("Read More")}
                          </a>
                        )}
                      </div>
                    </div>
                    <p className="promotion_action_area">
                      {isAuthenticated ? (
                        <Link className="common_btn" to="/dashboard/cashier">
                          {__("Claim Bonus")}
                        </Link>
                      ) : (
                        <a
                          className="common_btn"
                          href="javascript:"
                          onClick={() => {
                            props.setModalLoginPropsData(true);
                            setTimeout(function () {
                              props.setModalLoginPropsData(false);
                            }, 500);
                          }}
                        >
                          {__("Claim Bonus")}
                        </a>
                      )}{" "}
                      <Link
                        className="common_btn"
                        target="_blank"
                        to="/bonus-terms"
                      >
                        {__("Explore Terms")}
                      </Link>
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="row promotionVideoArea">
              <div className="col-sm-6 offset-sm-3 ">
                {selectedLanguage.code == "en" ? (
                  <h3 className="promotionTitle">
                    {__("Need help getting your 200% bonus?")}
                    <br />
                    {__("View below video!")}
                  </h3>
                ) : selectedLanguage.code == "pt" ? (
                  <h3 className="promotionTitle">
                    {__(
                      "Como obter o seu Bónus de Boas-vindas de 200%?"
                    )}
                    <br />
                    {__("Veja abaixo o vídeo!")}
                  </h3>
                ) : (
                  <h3 className="promotionTitle">
                    {__(
                      "¿Cómo obtener tu Bono de Bienvenida del 200%?"
                    )}
                    <br />
                    {__("¡Vea el vídeo a continuación!")}
                  </h3>
                )}
              </div>
              <div className="col-sm-6 offset-sm-3">
                {selectedLanguage.code == "en" ? (
                  <>
                    <div className="promotionVideo video-container">
                      <video
                        width="100%"
                        style={{ margin: 0, padding: 0, borderRadius: '30px' }}
                        autoPlay
                        muted
                        controls
                      >
                        <source
                          src={
                            require("assets/videos/promotions/english.mp4")
                              .default
                          }
                          type="video/mp4"
                        />
                        {__("Your browser does not support the video tag.")}
                      </video>
                    </div>
                  </>
                ) : selectedLanguage.code == "pt" ? (
                  <>
                    <div className="promotionVideo video-container">
                      <video
                        width="100%"
                        style={{ margin: 0, padding: 0, borderRadius: '30px' }}
                        autoPlay
                        muted
                        controls
                      >
                        <source
                          src={
                            require("assets/videos/promotions/portuguese.mp4")
                              .default
                          }
                          type="video/mp4"
                        />
                        {__("Your browser does not support the video tag.")}
                      </video>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="promotionVideo video-container">
                      <video
                        width="100%"
                        style={{ margin: 0, padding: 0 , borderRadius: '30px' }}
                        autoPlay
                        muted
                        controls
                      >
                        <source
                          src={
                            require("assets/videos/promotions/spanish.mp4")
                              .default
                          }
                          type="video/mp4"
                        />
                        {__("Your browser does not support the video tag.")}
                      </video>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
