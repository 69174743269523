import React, { useState, useEffect, useRef } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
import FAQ from "elements/FAQ";
import CategoryGameSlider from "elements/games_elements/CategoryGameSlider";
import api from "services/api";
import Slider from "react-slick";

import RankSystem from "./VIP/RankSystem";
import VipLevels from "./VIP/VipLevels";
import VIPClub from "./VIP/VIPClub";
import ProcessLevels from "./VIP/ProcessLevels";




export default function Vip(props) {
  const [selectedLanguage, setSelectedLanguage] = useState({});

  const getSelectedLanguage = async () => {
    var lang = await Translator.getSelectedLanguage();
    setSelectedLanguage(lang);
  };

  //Get Banners
  const [banners, setBanners] = useState([]);
  const getBanners = async (ev) => {
    var response = await api.get("/vip-banners");
    setBanners(response.data);
  };

  useEffect(() => {
    getBanners();
    getSelectedLanguage();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: false,
    // slidesToShow: 1,
    // slidesToScroll: 1,
  };

  const [pageContent, setPageContent] = useState("");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "vip",
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect(async () => {
    getContentPage();
  }, []);
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <>
      <div className="vipContainers">
        <RankSystem />
        <ProcessLevels />
        <VipLevels />
        <VIPClub />
      </div>


    </>
  );
}
